import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/functions';
import CryptoJS from 'crypto-js';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPaperPlane, faComment, faMagic, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import headerB182 from './Assets/Images/header-b182.png';
import headerNacs from './Assets/Images/header-nacs.png';

import a0 from './Assets/Images/a0.jpg';
import b1 from './Assets/Images/b1.jpg';
import c2 from './Assets/Images/c2.jpg';
import d3 from './Assets/Images/d3.gif';
import e4 from './Assets/Images/e4.jpg';
import f5 from './Assets/Images/f5.gif';

import a0Tall from './Assets/Images/a0_tall.jpg';
import b1Tall from './Assets/Images/b1_tall.jpg';
import c2Tall from './Assets/Images/c2_tall.jpg';
import d3Tall from './Assets/Images/d3_tall.gif';
import e4Tall from './Assets/Images/e4_tall.jpg';
import f5Tall from './Assets/Images/f5_tall.gif';
import { any } from 'prop-types';

firebase.initializeApp({
    apiKey: "AIzaSyDRL1qmNduhhcXg-kEd6EvSsT7CjVRoqX4",
    authDomain: "not-another-christmas-card.firebaseapp.com",
    databaseURL: "https://not-another-christmas-card.firebaseio.com",
    projectId: "not-another-christmas-card",
    storageBucket: "not-another-christmas-card.appspot.com",
    messagingSenderId: "741951346598",
    appId: "1:741951346598:web:1e344fad413c65e3602167",
    measurementId: "G-N3FH7Z8FWD"
  });

class Header extends React.Component<any, any> {
    constructor(props : any) {
		super(props);
		this.state = { navIsOpen: false };
	}

    render() {
        //const navIsOpen = this.state.navIsOpen;
        return (
            <>
                <div className="fixed-top shadow-lg" style={{ zIndex: 1000, height: 140, background: '#000' }}>
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-6 col-md-3 d-flex align-items-center justify-content-center justify-content-md-start order-1">
                                <h2 className="m-0 overflow-hidden" style={{ width: 130, height: 0, paddingTop: 65, background: `url('${headerB182}') center center no-repeat`, backgroundSize: 'contain' }}>Blink 182</h2>
                            </div>
                            <div className="col-md-6 d-flex justify-content-center align-items-center order-3 order-md-2">
                                <h1 className="m-0 overflow-hidden" style={{ width: 440, height: 0, paddingTop: 40, background: `url('${headerNacs}') center center no-repeat`, backgroundSize: 'contain' }}>Not Another Christmas Song</h1>
                            </div>
                            <div className="col-6 col-md-3 d-flex justify-content-center justify-content-md-end align-items-center order-2 order-md-3">
                                {/* <FontAwesomeIcon className="cursor-pointer" style={{ width: 30, height: 30, color: '#cc0000', marginLeft: 100 }} icon={faBars} onClick={() => this.setState({ navIsOpen: !navIsOpen })} /> */}
                                <iframe title="Not Another Christmas Song on Spotify" src="https://open.spotify.com/embed/album/4KflvArkaGbPj2jNgm70KK" width="80" height="80" frameBorder="0" allow="encrypted-media"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="position-fixed" style={{ zIndex: 1000, left: '100%', top: 140, bottom: 0, width: 300, background: '#000', transition: '200ms', marginLeft: navIsOpen ? -300 : 0 }}></div> */}
            </>
        );
    }
}

class Cards extends React.Component<any, any> {
    render() {
        const cards = [
            { code: 'a0', image: a0 },
            { code: 'b1', image: b1 },
            { code: 'c2', image: c2 },
            { code: 'd3', image: d3 },
            { code: 'e4', image: e4 },
            { code: 'f5', image: f5 },
        ].map((card:any, cardIndex:number) => 
            <div key={cardIndex} className="col-md-4 pb-2">
                <div className="bg-dark mb-4 shadow-sm rounded hover-scale overflow-hidden">
                    <img className="w-100 cursor-pointer" src={card.image} alt="" onClick={() => this.props.handleCardSelection(card.code, window.location.origin + card.image)} />
                </div>
            </div>
        );

        return (
            <>
                <div className="text-center">
                    <h3 className="mb-0" style={{ color: '#cc0000' }}>Christmas Card Generator</h3>
                    <p style={{ fontSize: 20 }}>Select a card to customize and send to your friends</p>
                </div>
                <div className="row py-4">
                    {cards}
                </div>

                <div className="text-center">
                    <h3 className="mb-0" style={{ color: '#cc0000' }}>Video Templates</h3>
                    <p style={{ fontSize: 20 }}>Download and Customize Yourself</p>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <VideoTemplate fileName="d3t.mp4" posterImage={d3Tall} />
                    </div>
                    <div className="col-md-4">
                        <VideoTemplate fileName="d3.mp4" posterImage={d3} />
                        <VideoTemplate fileName="f5.mp4" posterImage={f5} />
                    </div>
                    <div className="col-md-4">
                        <VideoTemplate fileName="f5t.mp4" posterImage={f5Tall} />
                    </div>
                </div>
            </>
        );
    }
}

class VideoTemplate extends React.Component<any, any> {
    render() {
        return (
            <>
                <div className="bg-dark mb-1 shadow-sm rounded overflow-hidden">
                    <video controls className="w-100 mb-0 d-block" poster={this.props.posterImage}>
                        <source src={`/Videos/${this.props.fileName}`} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
                <a className="btn btn-block active mb-4" href={`/Videos/${this.props.fileName}`} download={this.props.fileName}><FontAwesomeIcon icon={faDownload} /> Download</a>
            </>
        );
    }
}

class Generator extends React.Component<any, any> {
    imageLoader = new Image();

    constructor(props:any) {
        super(props);

        this.state = {
            recipientName: props.recipientName,
            recipientEmail: '',
            recipientPhone: '',
            senderName: props.senderName,
            senderEmail: '',
            cardCode: props.cardCode,
            cardImage: props.cardImage,
            cardIsLoaded: false,
            cardIsDirty: true,
            storageUri: null,
            verified: false,
            eMailingEcard: false,
            textingEcard: false,
            notifications: [],
            focusOnDownload: false,
            size: 'square'
        }

        this.eMailEcard = this.eMailEcard.bind(this);
        this.textEcard = this.textEcard.bind(this);
    }

    componentDidMount() {
        this.imageLoader.onload = () => {
            this.setState({
                cardIsLoaded: true
            });
        };
        this.imageLoader.src = this.state.cardImage;
    }

    async eMailEcard() {

        if(!this.state.verified) {
            this.setState({
                notifications: this.state.notifications.concat([{
                    message: 'Sorry! You could not be verified by reCaptcha. Try downloading your e-card instead.',
                    type: 'danger'
                }])
            });
            return false;
        }

        if(this.state.recipientName === '' || this.state.senderName === '') {
            this.setState({
                notifications: this.state.notifications.concat([{
                    message: 'Recipient and Sender names are required!',
                    type: 'danger'
                }])
            });
            return false;
        }
        if(this.state.recipientEmail === '' || this.state.senderEmail === '') {
            this.setState({
                notifications: this.state.notifications.concat([{
                    message: 'Recipient and Sender emails are required to send as e-mail!',
                    type: 'danger'
                }])
            });
            return false;
        }
        if(!this.emailIsValid(this.state.recipientEmail) || !this.emailIsValid(this.state.senderEmail)) {
            this.setState({
                notifications: this.state.notifications.concat([{
                    message: 'Recipient or Sender email is invalid!',
                    type: 'danger'
                }])
            });
            return false;
        }

        this.setState({ eMailingEcard: true });

        let cardImage = this.state.cardImage;
        if(this.state.cardCode === 'a0' || this.state.cardCode === 'c2' || this.state.cardCode === 'a0t' || this.state.cardCode === 'c2t') {
            if(this.state.cardIsDirty) {
                cardImage = await this.uploadCard();
            } else {
                cardImage = this.state.storageUri;
            }
        }

        const encryptedParameters = CryptoJS.AES.encrypt(`code=${this.state.cardCode}&from=${this.state.senderName}&to=${this.state.recipientName}`, 'blink').toString();
        const shareUri = `https://xmas.blink182.com/${this.state.cardCode}/#${encryptedParameters}`;

        firebase.functions().httpsCallable('eMailEcard')({
            senderName: this.state.senderName,
            senderEmail: this.state.senderEmail,
            recipientName: this.state.recipientName,
            recipientEmail: this.state.recipientEmail,
            eCardUri: cardImage,
            shareUri: shareUri
        }).then(result => {
            if(result.data) {
                this.setState({
                    eMailingEcard: false,
                    notifications: this.state.notifications.concat([{
                        message: 'Success! E-Card sent via E-Mail!',
                        type: 'success'
                    }])
                });   
            } else {
                this.setState({
                    eMailingEcard: false,
                    notifications: this.state.notifications.concat([{
                        message: 'Sorry! Something went wrong.',
                        type: 'danger'
                    }])
                });    
            }
        }).catch(result => {
            this.setState({
                notifications: this.state.notifications.concat([{
                    message: 'Sorry! Something went wrong.',
                    type: 'danger'
                }])
            });
            this.setState({ eMailingEcard: false });
        });
    }

    emailIsValid (email:string) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    async textEcard() {

        if(!this.state.verified) {
            this.setState({
                notifications: this.state.notifications.concat([{
                    message: 'Sorry! You could not be verified by reCaptcha. Try downloading your e-card instead.',
                    type: 'danger'
                }])
            });
            return false;
        }
        if(this.state.recipientName === '' || this.state.senderName === '') {
            this.setState({
                notifications: this.state.notifications.concat([{
                    message: 'Recipient and Sender names are required!',
                    type: 'danger'
                }])
            });
            return false;
        }
        if(this.state.recipientPhone === '') {
            this.setState({
                notifications: this.state.notifications.concat([{
                    message: 'Recipient Cell # is required to send as MMS!',
                    type: 'danger'
                }])
            });
            return false;
        }

        this.setState({ textingEcard: true });

        let cardImage = this.state.cardImage;
        if(this.state.cardCode === 'a0' || this.state.cardCode === 'c2' || this.state.cardCode === 'a0t' || this.state.cardCode === 'c2t') {
            if(this.state.cardIsDirty) {
                cardImage = await this.uploadCard();
            } else {
                cardImage = this.state.storageUri;
            }
        }

        const encryptedParameters = CryptoJS.AES.encrypt(`code=${this.state.cardCode}&from=${this.state.senderName}&to=${this.state.recipientName}`, 'blink').toString();
        const shareUri = `https://xmas.blink182.com/${this.state.cardCode}/#${encryptedParameters}`;

        if(this.state.cardCode === 'd3' || this.state.cardCode === 'f5' || this.state.cardCode === 'd3t' || this.state.cardCode === 'f5t') {
            firebase.functions().httpsCallable('textShareUri')({
                senderName: this.state.senderName,
                recipientPhone: this.state.recipientPhone,
                eCardUri: cardImage,
                shareUri: shareUri
            }).then(result => {
                if(result.data) {
                    this.setState({
                        textingEcard: false,
                        notifications: this.state.notifications.concat([{
                            message: 'Success! E-Card sent via MMS!',
                            type: 'success'
                        }])
                    });   
                } else {
                    this.setState({
                        textingEcard: false,
                        notifications: this.state.notifications.concat([{
                            message: 'Sorry! Something went wrong.',
                            type: 'danger'
                        }])
                    });    
                }
            }).catch(result => {
                this.setState({
                    notifications: this.state.notifications.concat([{
                        message: 'Sorry! Something went wrong.',
                        type: 'danger'
                    }])
                });
            });
        } else {
            firebase.functions().httpsCallable('textEcard')({
                senderName: this.state.senderName,
                recipientPhone: this.state.recipientPhone,
                eCardUri: cardImage
            }).then(result => {
                if(result.data) {
                    this.setState({
                        textingEcard: false,
                        notifications: this.state.notifications.concat([{
                            message: 'Success! E-Card sent via MMS!',
                            type: 'success'
                        }])
                    });   
                } else {
                    this.setState({
                        textingEcard: false,
                        notifications: this.state.notifications.concat([{
                            message: 'Sorry! Something went wrong.',
                            type: 'danger'
                        }])
                    });    
                }
            }).catch(result => {
                this.setState({
                    notifications: this.state.notifications.concat([{
                        message: 'Sorry! Something went wrong.',
                        type: 'danger'
                    }])
                });
            });
        }

    }

    async uploadCard() {
        const encryptedParameters = CryptoJS.AES.encrypt(`code=${this.state.cardCode}&from=${this.state.senderName}&to=${this.state.recipientName}&time=${(new Date())}`, 'blink').toString();
        const storageName = encryptedParameters.replace(/\//g, '').replace(/\\/g, '');

        const fetchResponse = await fetch(this.renderCard());
        const blob = await fetchResponse.blob();
        const reference = firebase.storage().ref(storageName + '.png');
        await reference.put(blob);
  
        const downloadUrl = await reference.getDownloadURL();
        this.setState({ cardIsDirty: false, storageUri: downloadUrl });

        return downloadUrl;
    }

    renderCard() {

        const cardCode = this.state.cardCode;
        const recipientName = this.state.recipientName;
        const senderName = this.state.senderName;

        let canvas = document.createElement('canvas');
        canvas.width = 1080;
        canvas.height = 1080;

        let context = canvas.getContext('2d');
        if(cardCode === 'a0') {
            context!.drawImage(this.imageLoader, 0, 0, canvas.width, canvas.height);
            context!.font = '28px Bebas Neue';
            context!.fillStyle = '#FFF';
            context!.fillText(recipientName.toUpperCase(), 168, 321);
            context!.fillText(senderName, 884, 843);
        } else if(cardCode === 'a0t') {
            canvas.height = 1920;
            context!.drawImage(this.imageLoader, 0, 0, canvas.width, canvas.height);
            context!.font = '35px Bebas Neue';
            context!.fillStyle = '#FFF';
            context!.fillText(recipientName.toUpperCase(), 128, 705);
            context!.fillText(senderName, 906, 1271);
        }else if(cardCode === 'c2') {
            context!.drawImage(this.imageLoader, 0, 0, canvas.width, canvas.height);
            context!.font = '28px Bebas Neue';
            context!.fillStyle = '#000';
            context!.fillText(recipientName.toUpperCase(), 326, 290);
            context!.fillText(senderName, 837, 324);
        }else if(cardCode === 'c2t') {
            canvas.height = 1920;
            context!.drawImage(this.imageLoader, 0, 0, canvas.width, canvas.height);
            context!.font = '35px Bebas Neue';
            context!.fillStyle = '#000';
            context!.fillText(recipientName.toUpperCase(), 252, 660);
            context!.fillText(senderName, 870, 700);
        }

        return canvas.toDataURL('image/png');
    }

    render() {
        const cardCode = this.state.cardCode;
        let cardImage = this.state.cardImage;
        const cardIsLoaded = this.state.cardIsLoaded;

        if(cardIsLoaded) {
            if(cardCode === 'a0' || cardCode === 'c2' || cardCode === 'a0t' || cardCode === 'c2t') {
                cardImage = this.renderCard();
            }
        }

        let cardCodeSquare:string = cardCode.substr(0, 2);
        let cardCodeTall:string = cardCode.substr(0, 2) + 't';
        let cardImageSquare:string = '';
        let cardImageTall:string = '';
        switch(cardCode.substr(0, 2)) {
            case 'a0':
                cardImageSquare = a0;
                cardImageTall = a0Tall;
                break;
            case 'b1':
                cardImageSquare = b1;
                cardImageTall = b1Tall;
                break;
            case 'c2':
                cardImageSquare = c2;
                cardImageTall = c2Tall;
                break;
            case 'd3':
                cardImageSquare = d3;
                cardImageTall = d3Tall;
                break;
            case 'e4':
                cardImageSquare = e4;
                cardImageTall = e4Tall;
                break;
            case 'f5':
                cardImageSquare = f5;
                cardImageTall = f5Tall;
                break;
        }

        const encryptedParameters = CryptoJS.AES.encrypt(`code=${this.state.cardCode}&from=${this.state.senderName}&to=${this.state.recipientName}`, 'blink').toString();
        const encodedShareUri = encodeURIComponent(`https://xmas.blink182.com/${this.state.cardCode}/#${encryptedParameters}`);

        return (
            <div className="row">
                <div className="col-md-7 col-lg-6 mb-4">
                    {cardIsLoaded && <img className="w-100 rounded shadow-sm" src={cardImage} />}
                </div>
                <div className="col-md-5 col-xl-4">

                    <div className={this.props.senderName && this.props.recipientName ? 'd-none' : ''}>
                        <div className="row">
                            <div className="form-group col-sm-6 col-md-12">
                                <label className="d-block text-center letters-spaced mb-0 h4" style={{color: '#cc0000'}}>Recipient Info</label>
                                <div className="input-group flex-nowrap" style={{ height: 35 }}>
                                    <div className="input-group-prepend">
                                        <label className="input-group-text bg-transparent border-left-0 border-top-0 border-right-0 rounded-0 border-bottom border-dark pl-2 pr-0" htmlFor="input-recipient-name">Name</label>
                                    </div>
                                    <input id="input-recipient-name" className="form-control form-control-lg h-100 bg-transparent border-left-0 border-top-0 border-right-0 rounded-0 border-bottom border-dark" type="text" value={this.state.recipientName} onChange={(event) => this.setState({ recipientName: event.target.value, cardIsDirty: true })} />
                                </div>
                                <div className="input-group flex-nowrap" style={{ height: 35 }}>
                                    <div className="input-group-prepend">
                                        <label className="input-group-text bg-transparent border-left-0 border-top-0 border-right-0 rounded-0 border-bottom border-dark pl-2 pr-0" htmlFor="input-recipient-email">Email</label>
                                    </div>
                                    <input id="input-recipient-email" className="form-control form-control-lg h-100 bg-transparent border-left-0 border-top-0 border-right-0 rounded-0 border-bottom border-dark" type="text" value={this.state.recipientEmail} onChange={(event) => this.setState({ recipientEmail: event.target.value })} />
                                </div>
                                <div className="input-group flex-nowrap" style={{ height: 35 }}>
                                    <div className="input-group-prepend">
                                        <label className="input-group-text bg-transparent border-left-0 border-top-0 border-right-0 rounded-0 border-bottom border-dark pl-2 pr-0" htmlFor="input-recipient-email">Cell #</label>
                                    </div>
                                    <input id="input-recipient-phone" className="form-control form-control-lg h-100 bg-transparent border-left-0 border-top-0 border-right-0 rounded-0 border-bottom border-dark" type="text" value={this.state.recipientPhone} onChange={(event) => this.setState({ recipientPhone: event.target.value })} />
                                </div>
                            </div>
                    
                            <div className="form-group mb-4 col-sm-6 col-md-12">
                                <label className="d-block text-center letters-spaced mb-0 h4" style={{color: '#cc0000'}}>Sender Info</label>
                                <div className="input-group flex-nowrap" style={{ height: 35 }}>
                                    <div className="input-group-prepend">
                                        <label className="input-group-text bg-transparent border-left-0 border-top-0 border-right-0 rounded-0 border-bottom border-dark pl-2 pr-0" htmlFor="input-sender-name">Name</label>
                                    </div>
                                    <input id="input-sender-name" className="form-control form-control-lg h-100 bg-transparent border-left-0 border-top-0 border-right-0 rounded-0 border-bottom border-dark" type="text" value={this.state.senderName} onChange={(event) => this.setState({ senderName: event.target.value, cardIsDirty: true })} />
                                </div>
                                <div className="input-group flex-nowrap" style={{ height: 35 }}>
                                    <div className="input-group-prepend">
                                        <label className="input-group-text bg-transparent border-left-0 border-top-0 border-right-0 rounded-0 border-bottom border-dark pl-2 pr-0" htmlFor="input-sender-email">Email</label>
                                    </div>
                                    <input id="input-sender-email" className="form-control form-control-lg h-100 bg-transparent border-left-0 border-top-0 border-right-0 rounded-0 border-bottom border-dark" type="text" value={this.state.senderEmail} onChange={(event) => this.setState({ senderEmail: event.target.value })} />
                                </div>
                            </div>
                        </div>
                        
                        <GoogleReCaptchaProvider reCaptchaKey="6Le0U8cUAAAAAIgVyc9m_eC-wQcVxkjpVO7wNauF">
                            <GoogleReCaptcha onVerify={() =>this.setState({ verified: true })} />
                        </GoogleReCaptchaProvider>

                        {this.state.notifications.map((notification:any, notificationIndex:number) =>
                            <div key={notificationIndex} className={`alert alert-${notification.type} mt-2 p-2 text-center`}>
                                {notification.message}
                                <button className="close" onClick={() => {
                                    let notifications = this.state.notifications;
                                    notifications.splice(0, 1);
                                    this.setState({ notifications });
                                }}>&times;</button>
                            </div>
                        )}

                        <div className="btn-group btn-group-toggle w-100 mb-1" data-toggle="buttons">
                            <button className={'btn' + (this.state.size == 'square' ? ' active' : '')} onClick={() => { this.setState({ cardCode: cardCodeSquare, cardImage: window.location.origin + cardImageSquare, size: 'square', cardIsLoaded: false, cardIsDirty: true }); this.imageLoader.src = window.location.origin + cardImageSquare; }}>Square</button>
                            <button className={'btn' + (this.state.size == 'tall' ? ' active' : '')} onClick={() => { this.setState({ cardCode: cardCodeTall, cardImage: window.location.origin + cardImageTall, size: 'tall', cardIsLoaded: false, cardIsDirty: true }); this.imageLoader.src = window.location.origin + cardImageTall; }}>Tall</button>
                        </div>
                        <div className="form-row mt-2">
                            <div className="col-sm-6 col-lg-6">
                                {!this.state.eMailingEcard && <button className="btn btn-block mb-2" onClick={this.eMailEcard}><FontAwesomeIcon icon={faPaperPlane} /> Send as Email</button>}
                                {this.state.eMailingEcard && <button className="btn btn-block mb-2" onClick={() => false} disabled><span className="spinner-border spinner-border-sm mb-1 mr-1"></span>Sending...</button>}
                            </div>
                            <div className="col-sm-6 col-lg-6">
                                {!this.state.textingEcard && <button className="btn btn-block mb-2" onClick={this.textEcard}><FontAwesomeIcon icon={faComment} /> Send as MMS</button>}
                                {this.state.textingEcard && <button className="btn btn-block mb-2" onClick={() => false} disabled><span className="spinner-border spinner-border-sm mb-1 mr-1"></span>Sending...</button>}
                            </div>
                        </div>
                            
                        <div className="form-row">
                            <div className="col-sm-6 col-md-12 col-lg-6">
                                <div className="btn btn-block mb-2">
                                    Share
                                    <a href={`https://twitter.com/share?text=${encodeURIComponent('A Special Holiday Message from blink-182 and Your Friend')}&url=${encodedShareUri}`} target="_blank" rel="noopener noreferrer" className="mr-1 ml-2"><FontAwesomeIcon icon={faTwitter} /></a>
                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedShareUri}`} target="_blank" rel="noopener noreferrer" className="mx-1"><FontAwesomeIcon icon={faFacebook} /></a>
                                    <button className="btn btn-link border-0 p-0 mx-1" style={{height: 20}} onClick={() => {
                                        this.setState({
                                            textingEcard: false,
                                            notifications: this.state.notifications.concat([{
                                                message: 'Download your card from a mobile device to share on Insta',
                                                type: 'success'
                                            }])
                                        });
                                        this.setState({ focusOnDownload: true });
                                    }}><FontAwesomeIcon icon={faInstagram} style={{ height: 20, width: 20, position: 'relative', top: -1 }} /></button>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-12 col-lg-6">
                                <a href={cardImage} download="NotAnotherChristmasCard_1x1.png" className="btn btn-block mb-2"><FontAwesomeIcon icon={faDownload} /> Download</a>
                            </div>
                        </div>
                        <div className="text-center p-3">
                            <a href="#" className="text-dark" onClick={() => this.props.handleCardSelection(null, null)}><FontAwesomeIcon icon={faArrowLeft} /> Go Back</a>
                        </div>
                    </div>

                    <div className={this.props.senderName && this.props.recipientName ? '' : 'd-none'}>
                        <p className="p-5 text-center">Your friend {this.props.senderName} sent you this blink-182 e-card.</p>
                        {this.state.notifications.map((notification:any, notificationIndex:number) =>
                            <div key={notificationIndex} className={`alert alert-${notification.type} mt-2 p-2 text-center`}>
                                {notification.message}
                                <button className="close" onClick={() => {
                                    let notifications = this.state.notifications;
                                    notifications.splice(0, 1);
                                    this.setState({ notifications });
                                }}>&times;</button>
                            </div>
                        )}

                            
                        <div className="form-row">
                            <div className="col-sm-6 col-md-12 col-lg-6">
                                <div className="btn btn-block mb-2">
                                    Share
                                    <a href={`https://twitter.com/share?text=${encodeURIComponent('A Special Holiday Message from blink-182 and Your Friend')}&url=${encodedShareUri}`} target="_blank" rel="noopener noreferrer" className="mr-1 ml-2"><FontAwesomeIcon icon={faTwitter} /></a>
                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedShareUri}`} target="_blank" rel="noopener noreferrer" className="mx-1"><FontAwesomeIcon icon={faFacebook} /></a>
                                    <button className="btn btn-link border-0 p-0 mx-1" style={{height: 20}} onClick={() => {
                                        this.setState({
                                            textingEcard: false,
                                            notifications: this.state.notifications.concat([{
                                                message: 'Download your card from a mobile device to share on Insta',
                                                type: 'success'
                                            }])
                                        });
                                        this.setState({ focusOnDownload: true });
                                    }}><FontAwesomeIcon icon={faInstagram} style={{ height: 20, width: 20, position: 'relative', top: -1 }} /></button>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-12 col-lg-6">
                                <a href={cardImage} download="NotAnotherChristmasCard_1x1.png" className="btn btn-block mb-2"><FontAwesomeIcon icon={faDownload} /> Download</a>
                            </div>
                        </div>
                            
                        <a href="#" className="btn btn-block mb-2" onClick={() => this.props.handleCardSelection(null, null)}><FontAwesomeIcon icon={faMagic} /> Make Your Own E-Card</a>
                    </div>

                </div>
            </div>
        );
    }
}

class App extends React.Component<any, any> {
    constructor(props:any) {
		super(props);

		this.state = {
            selectedCardCode: null,
            selectedCardImage: null,
            sharedCardData: null,
            senderName: '',
            recipientName: ''
        }
        
        this.selectCard = this.selectCard.bind(this);
    }

    componentDidMount() {
        if(document.location.hash) {
            const decryptedParameters = CryptoJS.AES.decrypt(document.location.hash.toString().substr(1), 'blink').toString(CryptoJS.enc.Utf8);
            const deserializedParameters = this.queryStringToJSON(decryptedParameters);
            
            let cardImage = null;
            switch(deserializedParameters.code) {
                case 'a0': cardImage = a0; break;
                case 'b1': cardImage = b1; break;
                case 'c2': cardImage = c2; break;
                case 'd3': cardImage = d3; break;
                case 'e4': cardImage = e4; break;
                case 'f5': cardImage = f5; break;
                case 'a0t': cardImage = a0Tall; break;
                case 'b1t': cardImage = b1Tall; break;
                case 'c2t': cardImage = c2Tall; break;
                case 'd3t': cardImage = d3Tall; break;
                case 'e4t': cardImage = e4Tall; break;
                case 'f5t': cardImage = f5Tall; break;
            }
            this.setState({
                selectedCardCode: deserializedParameters.code,
                selectedCardImage: cardImage,
                senderName: deserializedParameters.from,
                recipientName: deserializedParameters.to
            });
        }
    }

    queryStringToJSON(queryString:string) {            
        var pairs = queryString.split('&');
        
        var result:any = {};
        pairs.forEach(function(param:string) {
            let pair:string[] = param.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });
    
        return JSON.parse(JSON.stringify(result));
    }
    

    selectCard(cardCode:string, cardImage:string) {
        this.setState({
            selectedCardCode: cardCode,
            selectedCardImage: cardImage,
            senderName: '',
            recipientName: ''
        });
    }

    render() {
        return (
            <>
                <Header />
                <div className="container" style={{ paddingTop: 175 }}>
                    {this.state.selectedCardImage === null && <Cards handleCardSelection={this.selectCard} />}
                    {this.state.selectedCardImage && <Generator cardCode={this.state.selectedCardCode} cardImage={this.state.selectedCardImage} senderName={this.state.senderName} recipientName={this.state.recipientName} handleCardSelection={this.selectCard} />}
                </div>
            </>
        );
    }
}

ReactDOM.render(<App />, document.getElementById('root'));